import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'

import YoutubeWrapper from '../contents/home/YoutubeWrapper'
import SEO from '../layouts/Seo'
import LinkCarousel from '../components/Carousel/LinkCarousel'
import RegularSection from '../components/curves/RegularSection'

import HomeBreadcrumb from '../contents/home/HomeBreadcrumb'
import FluidImage from '../components/Image/FluidImage'
import HomeProducts from '../contents/home/HomeProducts'

const IndexPage = () => {
  const { getImageByName } = useStore()
  const imgSlideArray = [
    { img: getImageByName('304paslanmazhasirtel'), url: 'paslanmaz-celik-hasir', alt: '304 Kalite Paslanmaz Hasır Tel' },
    { img: getImageByName('316paslanmazhasirtel'), url: 'paslanmaz-celik-hasir', alt: '316 Kalite Paslanmaz Hasır Tel' },
    { img: getImageByName('galvanizhasirtel9'), url: 'galvaniz-celik-hasir', alt: 'Galvaniz Hasır Tel' },
    { img: getImageByName('celikhasirtel3'), url: 'paslanmaz-celik-hasir', alt: 'Çelik Hasır Tel İmalatı' },
    { img: getImageByName('paslanmazhasirtel5'), url: 'siyah-celik-hasir', alt: 'Asansör Seperatör Hasır Tel İmalatı' },
    { img: getImageByName('fensteli'), url: 'siyah-celik-hasir', alt: 'Fens Teli İmalatı' },
    { img: getImageByName('telkafes'), url: 'siyah-celik-hasir', alt: 'Punta Kaynaklı Hasır Tel' },
    { img: getImageByName('guvenlikbariyeri'), url: 'galvaniz-celik-hasir', alt: 'Güvenlik Bariyeri Hasır Tel' },
  ]
  return (
    <>
      <SEO
        title="Erez Kaynak - Çelik Hasır Tel ve Paslanmaz Filtre İmalatçısı - Hızlı ve Kaliteli Hizmet"
        description="Paslanmaz, galvanize yada çelik telden, tel kalınlığı ve ebat, göz aralığı fark etmeksizin punta kaynaklı hasır tel (çesan) imalatı yapılır.Paslanmaz elek teli ve paslanmaz filtre imalatçısı"
        keywords="hasır tel,hasır tel fiyatları,hasır tel imalatı,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı,filtre imalatı,elek imalatı,paslanmaz filtre,paslanmaz elek"
      />
      <HomeBreadcrumb />
      <HomeProducts />
      <RegularSection>
        <Box py={6}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
              <YoutubeWrapper>
                <FluidImage lazyHeight={500} fluid={getImageByName('youtubewrapper-1000').fluid} alt="erez kaynak tanıtım" />
              </YoutubeWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ContentHeader title="Çelik Hasır ve Filtre Elek İmalatı" />
              <ContentText>
                <p>
                  Müşterilerimizin istekleri doğrultusunda istenilen şekil, numune ya da projeye göre <strong>filtre</strong>,{' '}
                  <strong>elek</strong> imalatı gerçekleştirmekteyiz.
                </p>
                <p>
                  İstenilen tel kalınlığında ve ebatında, göz aralığı fark etmeksizin <strong>punta kaynaklı hasır(çesan)</strong> imalatı
                  yapmaktayız. Stok ürün yada özel sipariş için iletişime geçiniz.
                </p>
              </ContentText>
            </Grid>
          </Grid>
        </Box>
      </RegularSection>

      <RegularSection bgColor="rgba(0,0,0,.2)">
        <LinkCarousel imgArray={imgSlideArray} />
      </RegularSection>
    </>
  )
}
export default IndexPage
