import React, { memo } from 'react'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: 'auto',
    // justifySelf: 'center',
    margin: '0 auto',
  },
}))
const FluidImageNoShadow = ({ fluid, alt, style }) => {
  const classes = useStyles()
  // console.log(fluid)
  return (
    <div style={{ textAlign: 'center' }}>
      <Img
        className={classes.root}
        fluid={fluid}
        loading="eager"
        fadeIn={false}
        alt={alt || 'Erez Kaynak çelik hasır ve filtre elek imalatı'}
        style={style}
      />
    </div>
  )
}
export default memo(FluidImageNoShadow)
