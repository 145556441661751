import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  gridCard: {
    // color: '#fff',
    color: 'rgba(255,255,255,0.8)',
    '& h3': {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      paddingBottom: theme.spacing(2),
    },
    '& p': {
      // color: '#ddd',
      // color: 'rgba(255,255,255,0.5)',
      color: theme.palette.text.primary,
    },
  },
  cardNumber: {
    fontWeight: 500,
    color: theme.palette.text.disabled,
  },
}))
const BreadCrumbCard = ({ title, desc, cardNumber }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.gridCard}>
      <Box>
        <Typography component='div' variant='h2' align='center' className={classes.cardNumber}>
          {cardNumber}
        </Typography>
      </Box>
      <Typography component='h3' variant='h5' color='secondary' align='center'>
        {title}
      </Typography>
      <Typography paragraph align='center'>
        {desc}
      </Typography>
    </Grid>
  )
}
export default BreadCrumbCard
