import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    padding: theme.spacing(3),
    display: 'block',
    position: 'relative',
    zIndex: 1,
  },
}))
const RegularSection = ({ children, bgColor, color, hasBottom, hasTop }) => {
  const classes = useStyles()
  return (
    <section className={classes.root}>
      {hasTop && (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320' style={{ position: 'relative', bottom: '-100px', zIndex: 0 }}>
          <path fill={bgColor} fillOpacity='1' d='M0,192L1440,64L1440,320L0,320Z'></path>
        </svg>
      )}
      <div className={classes.wrapper} style={{ backgroundColor: bgColor, color: color }}>
        {children}
      </div>
      {hasBottom && (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320' style={{ position: 'relative', top: '-80px', zIndex: 0 }}>
          <path fill={bgColor} fillOpacity='1' d='M0,256L1440,128L1440,0L0,0Z'></path>
        </svg>
      )}
    </section>
  )
}
RegularSection.prototype = {
  hasTop: PropTypes.bool,
  hasBottom: PropTypes.bool,
  bgColor: PropTypes.string,
  color: PropTypes.string,
}
RegularSection.defaultProps = {
  hasTop: false,
  hasBottom: false,
  bgColor: '#212121',
  color: '#bdbdbd',
}
export default memo(RegularSection)
