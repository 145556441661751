import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ContentHeader from '../../components/Typography/ContentHeader'

import ContentText from '../../components/Typography/ContentText'
import BreadCrumbCard from '../../components/cards/BreadCrumbCard'
const useStyles = makeStyles((theme) => ({
  overlayDiv: {
    position: 'absolute',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.7)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9,
    // paddingTop: theme.spacing(),

    textAlign: 'center',
  },
  leftDiv: {
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(20),
    '& p': {
      color: 'rgba(255,255,255,0.8)',
      maxWidth: '800px',
      textAlign: 'justify',
      fontSize: '1.2em',
    },
    '& h1': {
      fontSize: '4em',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      '& div': {
        marginBottom: '0px',
      },
      '& h1': {
        fontSize: '1.7em',
        marginBottom: '0px',
      },
      '& span': {
        fontSize: '0.9em',
      },
      '& p': {
        fontSize: '0.9em',
      },
    },
  },
  overlayImage: {
    opacity: '0.2',
  },
  gridCardBox: {
    zIndex: '10',
    position: 'relative',
    top: '-180px',
    [theme.breakpoints.down('sm')]: {
      top: '0px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  gridCardWrapper: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[2],
    borderRadius: '10px',
    '& h2': {
      color: theme.palette.tonalOffset.dark,
      fontWeight: 500,
      paddingTop: theme.spacing(3),
      //
      // color: '#ddd',
      // color: 'rgba(255,255,255,0.4)',
    },
    '& .breadcard-desc': {
      paddingBottom: theme.spacing(2),
      fontSize: '1.35em',
    },
  },
}))
const HomeBreadcrumb = () => {
  const classes = useStyles()
  const { BreadCrumbImage } = useStaticQuery(graphql`
    query {
      BreadCrumbImage: file(relativePath: { eq: "kaynak.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return (
    <>
      <Box position='relative'>
        <div className={classes.overlayDiv}>
          <Box elevation={0} px={3} className={classes.leftDiv}>
            <ContentHeader title='PASLANMAZ ÇELİK HASIR' description='304 VEYA 316 KALİTEDE PASLANMAZ HASIR' />
            <ContentText>
              <p>
                304 veya 316 kalite paslanmaz telden istenilen ebatta ve göz aralığında 2mm ile 8mm arasında çelik hasır imalatı
                yapılmaktadır. Ayrıca boyasız, siyah, normal telden istenilen boy ve göz aralıklarında özel ölçüde çelik hasır imalatı
                yapılmaktadır.
              </p>
            </ContentText>
          </Box>
        </div>
        <Img className={classes.overlayImage} fluid={BreadCrumbImage.childImageSharp.fluid} loading='eager' fadeIn={false} />
      </Box>
      <Box px={6} className={classes.gridCardBox}>
        <Grid container spacing={3} className={classes.gridCardWrapper}>
          <Grid item xs={12}>
            <Typography component='h2' variant='h4' align='center'>
              Neden Biz?
            </Typography>
            <Typography paragraph align='center' color='textSecondary' className='breadcard-desc'>
              Firmamız çelik hasır sektöründe özel üretime yer vererek , müşterilerimize büyük bir kolaylık sağlamıştır.
            </Typography>
            <Divider />
          </Grid>
          <BreadCrumbCard
            cardNumber='#1'
            title='Kaliteli ve Dayanıklı'
            desc='Ürünlerimizdeki punta kaynaklar 1. sınıf işçilik olup, her üretim hattımız dayanıklılık testlerinden geçmektedir.'
          />
          <BreadCrumbCard
            cardNumber='#2'
            title='Vakit ve Para Tasarrufu'
            desc='Özel ölçüde imalat gerçekleştirerek hem müşterilerimizin hazır ürün aldıkdan sonra kesme işleminden kurtarıyoruz hemde kalan fire olmadığı için daha uygun fiyata çelik hasır temin ediyoruz.'
          />
          <BreadCrumbCard
            cardNumber='#3'
            title='Hızlı Üretim'
            desc='Acil çelik hasır ihtiyacı olan müşterilerimize anında geri dönüş yapıp ihtiyaçları doğrultusunda hızlı bir şekilde çelik hasır üretimine başlıyoruz.'
          />
        </Grid>
      </Box>
    </>
  )
}
export default HomeBreadcrumb
