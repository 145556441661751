import React, { memo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    cursor: 'pointer',
  },
  embedResponsive: {
    position: 'relative',
    paddingBottom: '56.25%',
    width: '100%',
    border: `6px solid rgba(0,0,0,.2)`,
    boxShadow: `0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12)`,
  },
  embedResponsiveItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))
const YoutubeWrapper = ({ children }) => {
  const [isImage, setIsImage] = useState(true)
  const classes = useStyles()
  return (
    <>
      {isImage ? (
        <div aria-hidden='true' onClick={() => setIsImage(false)} className={classes.imgWrapper} role='button'>
          {children}
        </div>
      ) : (
        <div className={classes.embedResponsive}>
          <iframe
            title='erez kaynak tanıtım'
            className={classes.embedResponsiveItem}
            src='https://www.youtube.com/embed/rZpN0Uyqp_o?autoplay=1&mute=1'
            frameborder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      )}
    </>
  )
}
export default memo(YoutubeWrapper)
