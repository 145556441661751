import React from 'react'
import { Link } from 'gatsby'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import useStore from '../../store/useStore'

import FluidImageNoShadow from '../../components/Image/FluidImageNoShadow'

const useStyles = makeStyles((theme) => ({
  productCard: {
    backgroundColor: 'rgba(0,0,0,.2)',
  },
  productImgWrapper: {
    margin: '20px',
    border: '5px solid #333',
  },
  productLink: {
    textDecoration: 'none !important',
  },
  productHeader: {
    color: theme.palette.primary.main,
  },
  productDesc: {
    color: 'rgba(255,255,255,.7)',
  },
}))
const HomeProducts = () => {
  const { getImageByName } = useStore()
  const classes = useStyles()
  const Products = [
    {
      img: getImageByName('celikhasirtel4'),
      url: '/paslanmaz-celik-hasir',
      title: 'Paslanmaz Çelik Hasır',
      desc: '304/316 paslanmaz hasır teknik bilgiler, paslanmaz hasır kullanım alanları, paslanmaz çelik hasır ölçü tablosu',
    },
    {
      img: getImageByName('siyahcelikhasir'),
      url: '/siyah-celik-hasir',
      title: 'Siyah Çelik Hasır',
      desc: 'Normal demir çelik hasır teknik bilgiler, normal demir çelik hasır kullanım alanları, normal demir çelik hasır ölçü tablosu',
    },
    {
      img: getImageByName('galvanizhasirtel9'),
      url: '/galvaniz-celik-hasir',
      title: 'Galvaniz Çelik Hasır',
      desc:
        'Galvaniz telden çelik hasır teknik bilgiler, galvaniz telden çelik hasır kullanım alanları, galvaniz telden çelik hasır ölçü tablosu',
    },
  ]

  return (
    <Box p={6}>
      <Grid container spacing={3}>
        {Products.map((itm) => (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Link to={itm.url} className={classes.productLink}>
              <Card className={classes.productCard}>
                <div className={classes.productImgWrapper}>
                  <FluidImageNoShadow fluid={itm.img?.fluid} alt={itm.title} />
                </div>
                <CardContent>
                  <Box textAlign="center">
                    <Typography component="h2" variant="strong" className={classes.productHeader}>
                      {itm.title}
                      <br />
                    </Typography>
                    <Typography className={classes.productDesc} style={{ paddingBottom: itm.title === 'Paslanmaz Çelik Hasır' && '25px' }}>
                      {itm.desc}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
export default HomeProducts
